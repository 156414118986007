export const environment = {
  production: false,
  frontendUrl: "https://cruscottoaimag.applika.biz/",
  endpointApi: "https://cruscottoaimagbe.applika.biz",
  awssdk: {
    bucket_name: "mivebobucket",
    user_id: "AKIA4HBCR3JYTG33FZOK",
    access_key: "AKIA4HBCR3JYTG33FZOK",
    secret_key: "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",
    region: "eu-central-1",
    url: "s3://mivebobucket/",
  },
  roles: {
    superAdmin: 0,
    admin: 1,
  },
};
