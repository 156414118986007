<mat-progress-bar class="progressbar" *ngIf="showLoading" mode="indeterminate"></mat-progress-bar>
<div class="bodyPage" *ngIf="!showLogin">
  <div class="first-column">
    <img src="../assets/images/logo.png" />
    <ul>
      <li>
        <mat-icon>account_circle</mat-icon>Utente:&nbsp;
        <i style="font-weight: bold">{{ user.username }}</i>
      </li>
      <li routerLink="dashboard" routerLinkActive="router-link-active"><mat-icon>home</mat-icon>Dashboard</li>
      <li routerLink="files" routerLinkActive="router-link-active"><mat-icon>folder</mat-icon>Files</li>
      <li (click)="logout()"><mat-icon>logout</mat-icon>Esci</li>
    </ul>
  </div>
  <div class="second-column">
    <mat-spinner *ngIf="showLoading" diameter="60"></mat-spinner>
    <router-outlet></router-outlet>
  </div>
</div>

<router-outlet *ngIf="showLogin"></router-outlet>
