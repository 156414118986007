<div class="horizon">
  <span class="company-name">AIMAG S.P.A</span>
  <span><i class="bi bi-geo-alt"></i>{{ mission.siteName }}</span>
  <span><i class="bi bi-calendar-event"></i>{{ mission.startDate | dateToIT }}</span>
  <span
    ><i class="bi bi-clock"></i>{{ mission.startTime | slice: 0:5 }} / {{
      mission.endTime | slice: 0:5
    }}</span
  >
</div>
<table class="table table-themed table-bordered table-hover table-responsive display responsive nowrap">
  <thead>
    <tr>
      <th scope="col">Dipendente</th>
      <th scope="col">Azienda</th>
      <th scope="col">Missione</th>
      <th scope="col">Inizio</th>
      <th scope="col">Fine</th>
      <th scope="col">Durata</th>
      <th scope="col">Stato</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td scope="row">Dipendente</td>
      <td>AIMAG S.P.A</td>
      <td>{{ mission.missionName }}</td>
      <td>{{ mission.startDate.slice(11,19) === "00:00:00" ? '-' : (mission.startDate | slice: 11:16) }} <br> <i class="bi bi-geo"></i> <span class="distance">{{ getDistance(mission.startDistance) }}</span></td>
      <td>{{ mission.endDate.slice(11,19) === "00:00:00" ? '-' : (mission.endDate | slice: 11:16) }} <br> <i class="bi bi-geo"></i><span class="distance">{{ getDistance(mission.endDistance) }}</span></td>
      <td>{{duration}}</td>
      <td>
        <span class="badge text-bg-primary">{{
          statuses[mission.taskStatus]
        }}</span>
      </td>
    </tr>
  </tbody>
</table>
