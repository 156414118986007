<mat-card style="height: 100%">
  <h1>File su cloud</h1>

  <div class="container" id="all-dialog">
    <div>
      <div class="sameRow" style="padding-bottom: 4px; align-items: center">
        <div>Elenco file (file permessi: PDF, JPEG, JPG, PNG, EXCEL, WORD)</div>
        <div>
          <label *ngIf="!enableDownloadButton"><b>SPAZIO CLOUD ESAURITO</b></label>
        </div>
      </div>

      <div class="progress-size">
        <div>Spazio cloud utilizzato:</div>
        <div>{{ sizeGbUsed | number : "1.0-5" }} / {{ cloudSizeMaxGb }} GB</div>
      </div>

      <div class="mt-5">
        <button type="button" mat-raised-button color="primary" (click)="fileInput.click()" *ngIf="user?.role !== 0">
          <i class="material-icons mr-2">cloud_upload</i>Carica file
        </button>
        <input hidden (change)="selectFile($event)" #fileInput type="file" id="file" />
      </div>

      <table style="margin-top: 10px" mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Download &nbsp;&nbsp;&nbsp; <span *ngIf="user?.level !== 0">Elimina</span></th>
          <td mat-cell *matCellDef="let element">
            <button mat-raised-button color="primary" (click)="downloadFile(element)">
              <mat-icon>download</mat-icon>
            </button>

            <button mat-raised-button color="warn" (click)="deleteFile(element)" style="margin-left: 2px" *ngIf="user?.level !== 0">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef>File</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.title }}

            <button mat-raised-button (click)="showFile(element)" style="margin-left: 2px">
              <!-- <mat-icon>search</mat-icon> -->
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[50]" showFirstLastButtons aria-label="Select page of periodic elements"> </mat-paginator>
    </div>

    <!-- <label class="btn btn-default">
      <input type="file" #uploader (change)="selectFile($event)" />
    </label> -->
  </div>
</mat-card>
