import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { LoadingService } from "./services/loading.service";
import { SessionService } from "./services/session.service";
import { User } from "./objects/User";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  showLoading = false;
  showFiller = false;
  showLogin = false;
  url = "";
  user: User;

  constructor(
    private loadingService: LoadingService,
    public dialog: MatDialog,
    private router: Router,
    private sessionService: SessionService,
    private activateRoute: ActivatedRoute
  ) {
    this.loadingService.loading.subscribe((loading) => {
      this.showLoading = loading;
    });

    this.url = this.router.url;
    console.log(`current url: ${this.url}`);

    const login = JSON.parse(this.sessionService.getGenericSession("login"));
    this.user = login;

    if (login == null || login == undefined) {
      let pathUrl = window.location.href.split("/")[3];
      this.showLogin = true;
      if (pathUrl !== "login") {
        window.location.href = "/login";
        this.showLogin = true;
      }
    } else {
      this.showLogin = false;
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  logout() {
    localStorage.clear();
    window.location.href = "login";
  }
}
