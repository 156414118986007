import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { LoginRequest, User } from "../objects/User";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private httpClient: HttpClient) {}

  getMissionsForAimag(): Promise<unknown> {
    var promise = new Promise((resolve, reject) => {
      const url = `${environment.endpointApi}/GetMissionsForAimag`;
      this.httpClient.get<Object>(url).subscribe(
        (response) => {
          resolve(response);
        },
        (err) => {
          console.log(`Error getting missions for aimag: ${err}`);
          reject(false);
        }
      );
    });
    return promise;
  }

  login(loginRequest: LoginRequest): Promise<unknown> {
    var promise = new Promise((resolve, reject) => {
      const url = `${environment.endpointApi}/api/Users/Login`;
      this.httpClient.post<User>(url, loginRequest).subscribe(
        (response) => {
          resolve(response);
          console.log(`login successful: ${response}`);
        },
        (err) => {
          console.log(`login error: ${err}`);
          reject(false);
        }
      );
    });
    return promise;
  }
}
