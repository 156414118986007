import { Component, HostListener, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LoginRequest, User } from "../objects/User";
import { LoadingService } from "../services/loading.service";
import { SessionService } from "../services/session.service";
import { SnackbarService } from "../services/snackbar.service";
import { ApiService } from "../services/api.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  username; // aimag
  password; // aimag2022!

  constructor(
    private snackBarService: SnackbarService,
    private loadingService: LoadingService,
    private router: Router,
    private sessionService: SessionService,
    private apiService: ApiService
  ) {
    const user = this.sessionService.getGenericSession("login");
    if (user != null || user != undefined) {
      window.location.href = "/dashboard";
    }
  }

  ngOnInit(): void {}

  login() {
    this.loadingService.showLoading();
    let loginRequest: LoginRequest = new LoginRequest();

    loginRequest.username = this.username;
    loginRequest.password = this.password;

    this.apiService
      .login(loginRequest)
      .then((response: User) => {
        if (response) {
          this.snackBarService.openSnackBar("Accesso avvenuto con successo");
          this.sessionService.setGenericSession(response, "login");
          this.loadingService.hideLoading();
          window.location.href = "/dashboard";
        } else {
          this.snackBarService.openSnackBar("Controllare le credenziali e riprovare l'accesso");
          this.loadingService.hideLoading();
        }
      })
      .catch(() => {
        this.snackBarService.openSnackBar("Controllare le credenziali e riprovare l'accesso");
        this.loadingService.hideLoading();
      });
  }
}
