<mat-card class="bodyContent">
  <div class="topper">
    <div class="finderTop">
      &nbsp;&nbsp; &nbsp;
      <mat-form-field appearance="fill">
        <mat-label>Inizio</mat-label>
        <input
          matInput
          style="font-weight: bolder; color: black"
          [ngxMatDatetimePicker]="picker"
          placeholder=""
          [formControl]="dateControlStart"
          disabled
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker
          disabled="false"
          #picker
          color="primary"
        ></ngx-mat-datetime-picker>
      </mat-form-field>
      &nbsp;&nbsp;
      <mat-form-field appearance="fill">
        <mat-label>Fine</mat-label>
        <input
          matInput
          style="font-weight: bolder; color: black"
          [ngxMatDatetimePicker]="picker2"
          placeholder=""
          [formControl]="dateControlEnd"
          disabled
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <ngx-mat-datetime-picker
          disabled="false"
          #picker2
          color="primary"
        ></ngx-mat-datetime-picker>
      </mat-form-field>
      &nbsp;&nbsp;
      <mat-form-field appearance="fill">
        <mat-label>Filtra per cantiere</mat-label>
        <mat-select [formControl]="selectedSiteName">
          <mat-option value="all">Tutti</mat-option>
          <mat-option *ngFor="let site of sites" [value]="site">{{
            site
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      &nbsp;&nbsp;
      <button mat-raised-button (click)="filterMissions()">
        <mat-icon>search</mat-icon> CERCA
      </button>
    </div>
  </div>

  <app-mission
    *ngFor="let mission of filteredMissions"
    [mission]="mission"
  ></app-mission>
</mat-card>
