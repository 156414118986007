import { Component, Input } from "@angular/core";
import { Mission } from "../objects/Mission";

@Component({
  selector: "app-mission",
  templateUrl: "./mission.component.html",
  styleUrls: ["./mission.component.scss"],
})
export class MissionComponent {
  @Input() mission: Mission;
  statuses = ["Sconosciuto", "In corso", "Completato", "Da completare"];
  duration = "-h --m";

  ngOnInit() {
    const [startDate, startMonth, startRest] =
      this.mission.startDate.split("/");
    const [endDate, endMonth, endRest] = this.mission.endDate.split("/");
    const duration =
      new Date(`${endMonth}/${endDate}/${endRest}`).getTime() -
      new Date(`${startMonth}/${startDate}/${startRest}`).getTime();
    this.duration = this.getDurationInFormat(duration);
  }

  private getDurationInFormat(duration: number): string {
    let seconds = Math.floor(duration / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    // 👇️ if seconds are greater than 30, round minutes up (optional)
    minutes = seconds >= 30 ? minutes + 1 : minutes;

    minutes = minutes % 60;

    // 👇️ If you don't want to roll hours over, e.g. 24 to 00
    // 👇️ comment (or remove) the line below
    // commenting next line gets you `24:00:00` instead of `00:00:00`
    // or `36:15:31` instead of `12:15:31`, etc.
    hours = hours % 24;

    if (hours < 0) return '-'

    return `${this.padTo2Digits(hours)}h ${this.padTo2Digits(minutes)}m`;
  }

  private padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  getDistance(distance) {
    const parsedDistance = parseFloat(distance)
    if (parsedDistance >= 1.0) {
      return `${parsedDistance.toFixed(2)} km`
    }

    return `${(parsedDistance * 1000).toFixed(2)} m`
  }
}
