export class User {
  id!: number;
  username!: string;
  name!: string;
  surname!: string;
  role!: number;
  creationDate!: string;
  lastLoginDate!: string;
  password!: string;
}

export class LoginRequest {
  username!: string;
  password!: string;
}
