import { DatePipe } from "@angular/common";
import { Component, HostListener, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Mission } from "../objects/Mission";
import { ApiService } from "../services/api.service";
import { LoadingService } from "../services/loading.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent {
  dateControlStart = new FormControl(new Date());
  dateControlEnd = new FormControl(new Date());
  selectedSiteName = new FormControl("all");
  @ViewChild("picker1") picker1: any;
  @ViewChild("picker2") picker2: any;
  allMissions: Mission[];
  filteredMissions: Mission[];
  sites: Array<string> = [];

  constructor(
    private loadingService: LoadingService,
    private apiService: ApiService,
    private dialog: MatDialog,
    private datePipe: DatePipe
  ) {
    let today = this.datePipe.transform(new Date(), "yyyy-MM-ddT05:00:00");
    let sevendays = this.datePipe.transform(
      new Date().setDate(new Date().getDate() + 7),
      "yyyy-MM-ddT00:00:00"
    );

    this.dateControlStart.setValue(today);
    this.dateControlEnd.setValue(sevendays);

    this.getMissionsForAimag();
  }

  getMissionsForAimag() {
    this.loadingService.showLoading();
    this.apiService.getMissionsForAimag().then((response: Mission[]) => {
      if (response) {
        this.allMissions = response;
        this.generateSiteNames();
        this.filterMissions();
        this.loadingService.hideLoading();
      }
      this.loadingService.hideLoading();
    });
  }

  private generateSiteNames() {
    this.sites = this.allMissions
      .map(({ siteName }) => siteName)
      .reduce((siteNames, siteName) => {
        if (siteNames.indexOf(siteName) < 0) {
          siteNames.push(siteName);
        }
        return siteNames;
      }, []);
  }

  filterMissions() {
    this.loadingService.showLoading();
    const startDateTS = new Date(this.dateControlStart.value).getTime();
    const endDateTS = new Date(this.dateControlEnd.value).getTime();
    const selectedSiteName = this.selectedSiteName.value;

    this.filteredMissions = this.allMissions.filter((mission) => {
      const [startDate, startMonth, startRest] = mission.startDate.split("/");
      const [endDate, endMonth, endRest] = mission.endDate.split("/");
      return (
        new Date(`${startMonth}/${startDate}/${startRest}`).getTime() >
          startDateTS &&
        new Date(`${endMonth}/${endDate}/${endRest}`).getTime() < endDateTS &&
        (selectedSiteName === "all"
          ? true
          : mission.siteName === selectedSiteName)
      );
    });
    //.reverse();
    this.loadingService.hideLoading();
  }

  @HostListener('window:beforeunload', [ '$event' ])
  beforeUnloadHandler(event) {
    //localStorage.clear();
  }
}
