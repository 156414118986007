import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateToIT'
})
export class DateToITPipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {}

  transform(date: string): string {
    const [day, month, rest] = date.split('/');
    const dateInMMDDYYYY = new Date(`${month}/${day}/${rest}`);
    // console.log(dateInMMDDYYYY);
    return this.datePipe.transform(dateInMMDDYYYY, 'EEEE, MMMM d, y');
  }

}
