import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { User } from "../objects/User";
import { LoadingService } from "../services/loading.service";
import { S3BucketService } from "../services/s3bucket.service";
import { SessionService } from "../services/session.service";
import { SnackbarService } from "../services/snackbar.service";

export class Base64FileListTemp {
  name: string;
  base64: ArrayBuffer | string;
}

export class S3Object {
  id: string;
  size: number;
  title: string;
  url: string;
}

@Component({
  selector: "app-files",
  templateUrl: "./files.component.html",
  styleUrls: ["./files.component.scss"],
})
export class FilesComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  fileListUploaded: FileList[] = [];
  fileListUploadedBase64: Base64FileListTemp[] = [];
  displayedColumns: string[] = ["name", "position"];
  dataSource;
  roleGet = null;
  cloudSizeMaxGb = 10;
  sizeGbUsed = 0;
  enableDownloadButton = false;
  filename = "";
  s3Object: S3Object[] = [];
  user: User;

  @ViewChild("picker1") picker1: any;
  dateControl = new FormControl(new Date());

  constructor(
    private sessionService: SessionService,
    public snackBarService: SnackbarService,
    private loadingService: LoadingService,
    private uploadServiceS3: S3BucketService,
    public dialog: MatDialog
  ) {
    this.uploadServiceS3.loadTracksFromS3();
  }

  ngOnInit(): void {
    this.user = JSON.parse(this.sessionService.getGenericSession("login"));

    this.uploadServiceS3.tracksList$.subscribe((s3FileList) => {
      console.log("s3FileList: ", s3FileList);
      this.s3Object = s3FileList as S3Object[];

      this.s3Object.forEach((x) => {
        this.sizeGbUsed = x.size + x.size;
      });

      this.enableDownloadButton = !(this.sizeGbUsed >= 10000);
      this.dataSource = new MatTableDataSource<Object>(s3FileList.reverse());
      this.dataSource.paginator = this.paginator;
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  selectFile(event) {
    this.fileListUploaded.push(event.target.files);
    this.getBase64(event.target.files[0]);

    console.log(`filename: ${event.target.files[0].name} --- type: ${event.target.files[0].type}`);
    console.log(`fileListUploaded: ${this.fileListUploaded}`);
    const name = event.target.files[0].name;
    const file = event.target.files[0];

    // this.fileListUploaded.forEach((x, i) => {
    this.loadingService.showLoading();
    this.uploadServiceS3
      .uploadFile(file, name)
      .then((response) => {
        if (response) {
          this.snackBarService.openSnackBar("File caricato con successo");
          this.uploadServiceS3.loadTracksFromS3();
          this.loadingService.hideLoading();
        } else {
          this.snackBarService.openSnackBar("Errore durante il caricamento del file");
          this.loadingService.hideLoading();
        }
      })
      .catch((err) => {
        this.loadingService.hideLoading();
      });
  }

  getBase64(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // console.log(reader.result);
      let tmp: Base64FileListTemp = new Base64FileListTemp();
      tmp.base64 = reader.result;
      tmp.name = file.name;
      this.fileListUploadedBase64.push(tmp);
      // console.log('this.fileListUploadedBase64 ' , this.fileListUploadedBase64)
    };
  }

  downloadFile(element) {
    this.uploadServiceS3.downloadFile(element.title);
  }

  deleteFile(element) {
    this.loadingService.showLoading();
    this.uploadServiceS3.deleteFile(element.title).then((response) => {
      if (response) {
        this.snackBarService.openSnackBar("Eliminato con successo");
        this.loadingService.hideLoading();
      } else {
        this.snackBarService.openSnackBar("Errore eliminazione");
        this.loadingService.hideLoading();
      }
    });
  }

  @HostListener("window:beforeunload", ["$event"])
  beforeUnloadHandler(event) {
    //localStorage.clear();
  }
}
