import { Injectable } from "@angular/core";
import * as AWS from "aws-sdk/global";
import * as S3 from "aws-sdk/clients/s3";
import { environment } from "src/environments/environment";
import { SnackbarService } from "./snackbar.service";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class S3BucketService {
  private tracksListSubject = new BehaviorSubject([]);
  public tracksList$: Observable<object[]> =
    this.tracksListSubject.asObservable();

  constructor(private snackBarService: SnackbarService) {}

  public loadTracksFromS3() {
    console.log("loading tracks from S3...");
    const s3 = new S3({
      accessKeyId: environment.awssdk.access_key,
      secretAccessKey: environment.awssdk.secret_key,
      region: environment.awssdk.region,
    });

    const params = {
      Bucket: environment.awssdk.bucket_name,
    };

    s3.listObjects(params, (err, data) => {
      if (err) {
        console.log("error!", err);
      }

      const raw = data.Contents;
      const tracks: object[] = [];

      raw.forEach((item) => {
        tracks.push({
          id: item.ETag,
          title: item.Key,
          url: environment.awssdk.url + item.Key,
          size: Math.round((item.Size / 1024 / 1024) * 10) / 10,
        });
      });

      console.log(tracks.length, "tracks loaded");
      this.tracksListSubject.next(tracks);
    });
  }

  uploadFile(file, filename): Promise<unknown> {
    const fileSize = file.size / 1024;
    const fileType = file.type;
    const typesAllowed = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "application/pdf",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ];

    console.log(`file: ${file} ----- filename: ${filename}`)

    var promise = new Promise((resolve, reject) => {
      if (fileSize > 8000) {
        this.snackBarService.openSnackBar(
          "Errore, dimensioni del file troppo grandi. Il file deve essere inferiore agli 8MB."
        );
        reject(false);
      } else {
        if (typesAllowed.includes(fileType)) {
          const contentType = file.type;
          const bucket = new S3({
            accessKeyId: environment.awssdk.access_key,
            secretAccessKey: environment.awssdk.secret_key,
            region: environment.awssdk.region,
          });
          const params = {
            Bucket: environment.awssdk.bucket_name,
            Key: filename,
            Body: file,
            ACL: "public-read",
            ContentType: contentType,
          };
          bucket.upload(params, function (err, data) {
            if (err) {
              console.log("There was an error uploading your file: ", err);
              reject(false);
            }

            console.log("Successfully uploaded file.", data);
            resolve(true);
          });
          
          //this.loadTracksFromS3();
        } else {
          this.snackBarService.openSnackBar("Errore, file non permesso.");
          reject(false);
        }
      }
    });

    return promise;
  }

  downloadFile(fileName: string): Promise<unknown> {
    var promise = new Promise((resolve, reject) => {
      const bucket = new S3({
        accessKeyId: environment.awssdk.access_key,
        secretAccessKey: environment.awssdk.secret_key,
        region: environment.awssdk.region,
      });

      const params = {
        Bucket: environment.awssdk.bucket_name,
        Key: fileName,
      };

      bucket.getObject(params, (err: any, data: any) => {
        debugger;
        if (err) {
          // shows AWS s3 error
          console.log("Error download: ", err);
          this.snackBarService.openSnackBar(
            "Questo file non è ancora stato caricato sul cloud e di conseguenza non si può ancora scaricare, premi SALVA per caricarlo sul Cloud e renderlo disponibile al Download."
          );

          reject(false);
        } else {
          // response of binary data
          // use your download function here
          console.log("Success download: ", data);
          var blob = this.base64ToBlob(
            this._arrayBufferToBase64(data.Body),
            data.ContentType
          );
          const objectUrl: string = URL.createObjectURL(blob);
          const a: HTMLAnchorElement = document.createElement(
            "a"
          ) as HTMLAnchorElement;

          debugger;

          // hack per il download del file
          a.href = objectUrl;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(objectUrl);

          // resolve(this._arrayBufferToBase64(data.Body)); // questo torna il base64 del file se serve
          resolve(true);
        }
      });
    });
    return promise;
  }

  downloadFileReturnBase64(fileName: string): Promise<unknown> {
    var promise = new Promise((resolve, reject) => {
      const bucket = new S3({
        accessKeyId: environment.awssdk.access_key,
        secretAccessKey: environment.awssdk.secret_key,
        region: environment.awssdk.region,
      });
      const params = {
        Bucket: environment.awssdk.bucket_name,
        Key: fileName,
      };
      bucket.getObject(params, (err: any, data: any) => {
        if (err) {
          // shows AWS s3 error
          console.log("Error download: ", err);
          this.snackBarService.openSnackBar(
            "Questo file non è ancora stato caricato sul cloud e di conseguenza non si può ancora scaricare, premi SALVA per caricarlo sul Cloud e renderlo disponibile al Download."
          );

          reject(false);
        } else {
          // response of binary data
          // use your download function here
          console.log("Success download: ", data);
          var blob = this.base64ToBlob(
            this._arrayBufferToBase64(data.Body),
            data.ContentType
          );
          const objectUrl: string = URL.createObjectURL(blob);
          // const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

          // hack per il download del file
          // a.href = objectUrl;
          // a.download = fileName;
          // document.body.appendChild(a);
          // a.click();
          // document.body.removeChild(a);
          // URL.revokeObjectURL(objectUrl);

          resolve(this._arrayBufferToBase64(data.Body)); // questo torna il base64 del file se serve
          // resolve(true);
        }
      });
    });
    return promise;
  }

  deleteFile(fileName: string): Promise<unknown> {
    var promise = new Promise((resolve, reject) => {
      const bucket = new S3({
        accessKeyId: environment.awssdk.access_key,
        secretAccessKey: environment.awssdk.secret_key,
        region: environment.awssdk.region,
      });
      var params = {
        Bucket: environment.awssdk.bucket_name,
        Key: fileName,
      };
      bucket.deleteObject(params, (err: any, data: any) => {
        if (err) {
          // shows AWS s3 error
          console.log("Error delete file: ", err);
          reject(false);
        } else {
          // response of binary data
          // use your download function here
          console.log("Success delete");
          resolve(true);
          this.loadTracksFromS3();
        }
      });
    });
    return promise;
  }

  _arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  base64ToBlob(b64Data, contentType = "", sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}
